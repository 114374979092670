/*
 * Flex
 *
 * Simply utility class for making things flexy.
 */
.flex { 
	display: flex;

	&.--justify-center 	{ justify-content: center; }
	&.--justify-right	{ justify-content: flex-end; }
	&.--justify-space	{ justify-content: space-between; }
	&.--align-center	{ align-items: center; }
	&.--align-bottom	{ align-items: flex-end; }
	&.--align-self-end	{ align-self: flex-end; }

	&.--wrap 			{ flex-wrap: wrap; }
	&.--wrap-reverse 	{ flex-wrap: wrap-reverse; }
	&.--no-wrap 		{ flex-wrap: nowrap; }

	&.--row				{ flex-direction: row; }
	&.--column			{ flex-direction: column; }
}
