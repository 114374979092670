/*
 * Typography
 *
 * Control site fonts.
 */



/*
 * Base
 */
html,
body,
:root {
    font-family: $font-main;
    color: $black;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: grayscale;
}



/*
 * Load Fonts
 */
// @font-face {
//         font-family: 'Whitney';
//         font-display: auto;
//         src: 	url('../../fonts/Whitney-Light.eot?#iefix') format('embedded-opentype'),  
//                 url('../../fonts/Whitney-Light.otf')  format('opentype'),
//             url('../../fonts/Whitney-Light.woff') format('woff'), 
//             url('../../fonts/Whitney-Light.ttf')  format('truetype'), 
//             url('../../fonts/Whitney-Light.svg#Whitney-Light') format('svg');
//         font-weight: normal;
//         font-style: normal;
// }



/*
 * headlines, paragraphs, and lists
 */
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
    margin: 0;

    &:first-child {
        margin-top: 0;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-main;
}

h1 {
    font-size: 40px;
    font-weight: 900;
    line-height: 48px;
    margin-top: 16px;
}

h2 {
    font-size: 28px;
    line-height: 33px;
    margin-top: 12px;

    @include respond-min($md) {
        font-size: 40px;
        line-height: 48px;
        margin-top: 16px;
    }

    @include respond-min($lg) {
        font-size: 48px;
        line-height: 56px;
    }
}

h3 {
    font-size: 20px;
    line-height: 24px;
    margin-top: 12px;

    @include respond-min($md) {
        font-size: 32px;
        line-height: 40px;
        margin-top: 16px;
    }

    @include respond-min($lg) {
        font-size: 40px;
        line-height: 48px;
    }
}

h4 {
    font-size: 18px;
    line-height: 26px;
    margin-top: 12px;

    @include respond-min($md) {
        font-size: 24px;
        line-height: 32px;
        margin-top: 16px;
    }

    @include respond-min($lg) {
        font-size: 32px;
        line-height: 40px;
    }
}

h5 {
    font-size: 16px;
    line-height: 24px;
    margin-top: 12px;

    @include respond-min($md) {
        font-size: 18px;
        line-height: 26px;
        margin-top: 16px;
    }

    @include respond-min($lg) {
        font-size: 24px;
        line-height: 32px;
    }
}

h6 {
    font-size: 14px;
    line-height: 22px;
    margin-top: 12px;

    @include respond-min($md) {
        font-size: 16px;
        line-height: 24px;
        margin-top: 16px;
    }
}

p,
ul,
ol {
    font-size: 14px;
    line-height: 20px;
    margin-top: 12px;

    @include respond-min($md) {
        font-size: 16px;
        line-height: 24px;
        margin-top: 16px;
    }
}

p.lead {
    font-size: 18px;
    line-height: 24px;

    @include respond-min($md) {
        font-size: 24px;
        line-height: 32px;
    }
}



/* Links
    ========================================================================== */

a { 
    text-decoration:none;
    outline: none;
    border: 0;
    color: $black;
    cursor: pointer;
}

a:focus {
    outline: none;
}



/* Selections
    ========================================================================== */

::selection {
    background: $selection-bg-color;
    color: $selection-txt-color;
    text-shadow:none;
}

::-webkit-selection {
    background: $selection-bg-color;
    color: $selection-txt-color;
    text-shadow:none;
}

::-moz-selection {
    background: $selection-bg-color;
    color: $selection-txt-color;
    text-shadow:none;
}
