/*
 * Sections
 *
 * All section styles.
 */



main {
     text-align: center;

    a {
        display: inline-block;
        margin: 32px 0 0 16px;

        &:first-child {
            margin-left: 0;
        } 
    }
}

svg {
     width: 100%;
     max-width: 176px;
     height: auto;

    &.icon {
        max-width: 40px;
    }
}
