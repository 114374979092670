/*
 * Variables
 *
 * Make it shine in a few changes baby.
 */



/*
 * Colors
 */
$black:						#000000;
$white: 					#FFFFFF;

// Brand Colors
$brand-biege:			    #FFF4E4;
$brand-red:                 #F1573A;

// RGBA Values
$black-75:		rgba(49, 49, 51, 0.75);

// Background of tags
$bg-body:	$brand-biege;
   
   
   
/*
 * Typepography
 */
$font-main: Whitney, helvetica, sans-serif;
$font-head: Whitney, helvetica, sans-serif;
$font-copy: helvetica, sans-serif;

// Selection
$selection-bg-color: $black;
$selection-txt-color: $white;
   
   
   
/*
 * Grid
 */
   
// Max width of .grid-container
$grid-max-width: 100%;

// Padding of .grid-container
$grid-padding-xs: 24px;
$grid-padding-sm: 32px;
$grid-padding-md: 40px;
$grid-padding-lg: 56px;
$grid-padding-xl: 72px;
   
   
   
/*
 * Breakpoints
 */
   
// Default
$xl:	1366px;
$lg: 	1024px;
$md: 	768px;
$sm: 	425px;
$xs: 	375px;

// Used in special cases
$container: 1366px;
$menu-mobile: 900px;
