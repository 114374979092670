/*
 * Base
 * 
 * Base styles of the site.
 */
html, 
body {
    margin: 0;
    background-color: $bg-body;
}

html { 
    box-sizing: border-box;
    scroll-behavior: smooth;

    // Disable smooth scrolling when users have prefers-reduced-motion enabled.
    @include reduced-motion(reduce) {
        scroll-behavior: auto;
    }
}

*, 
*:before, 
*:after { 
    box-sizing: inherit;
}



/*
 * Sections
 */
section { position: relative; }