/* ==========================================================================
   Mixins
   ========================================================================== */



/* Mixins Variables
========================================================================== */

$inandoutqaud: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$cb-bounce: cubic-bezier(0.855, -0.600, 0.135, 1.650);



/* Mixins Transitions
========================================================================== */

@mixin transition-cb-bounce($transition) {
	-webkit-transition: $transition 300ms $cb-bounce;
	-moz-transition: 	$transition 300ms $cb-bounce;
    -o-transition: 		$transition 300ms $cb-bounce;
    transition: 		$transition 300ms $cb-bounce;
}

@mixin transition-200($transition) {
	-webkit-transition: $transition 200ms $inandoutqaud;
	-moz-transition: 	$transition 200ms $inandoutqaud;
    -o-transition: 		$transition 200ms $inandoutqaud;
    transition: 		$transition 200ms $inandoutqaud;
}



/* Mixins Media Queries
========================================================================== */

@mixin reduced-motion($value){
    @media screen and (prefers-reduced-motion: $value) {
        @content;
    }
}

@mixin respond-min($width){
    @media screen and (min-width: $width) {
        @content;
    }
}

@mixin respond-max($width) {
    @media screen and (max-width: $width) {
        @content;
    }
}

@mixin respond-range($min, $max) {
	@media screen and (min-width: $min) and (max-width: $max) {
		@content;
	}
}

@mixin respond-range-multi($min, $max, $min2, $max2) {
	@media screen and (min-width: $min) and (max-width: $max), screen and (min-width: $min2) and (max-width: $max2) {
		@content;
	}
}

@mixin respond-range-orientation-multi($min, $max, $orientation, $max2) {
	@media screen and (min-device-width: $min) and (max-device-width: $max) and (orientation: $orientation), screen and (max-width: $max2) {
		@content;
	}
}

@mixin respond-high-dpi($resolution) {
    @media screen and (-webkit-min-device-pixel-ratio : $resolution), screen and (min-device-pixel-ratio : $resolution) {
        @content;
    }
}
