/*
 * Main
 *
 * Load in and control site styles here.
 */
/*
 * Variables and Mixins
 */
/*
 * Variables
 *
 * Make it shine in a few changes baby.
 */
/*
 * Colors
 */
/*
 * Typepography
 */
/*
 * Grid
 */
/*
 * Breakpoints
 */
/* ==========================================================================
   Mixins
   ========================================================================== */
/* Mixins Variables
========================================================================== */
/* Mixins Transitions
========================================================================== */
/* Mixins Media Queries
========================================================================== */
/*
 * Vendor
 */
/*
 * Partials
 */
/*
 * Base
 * 
 * Base styles of the site.
 */
html,
body {
  margin: 0;
  background-color: #FFF4E4;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/*
 * Sections
 */
section {
  position: relative;
}

/*
 * Flex
 *
 * Simply utility class for making things flexy.
 */
.flex {
  display: flex;
}
.flex.--justify-center {
  justify-content: center;
}
.flex.--justify-right {
  justify-content: flex-end;
}
.flex.--justify-space {
  justify-content: space-between;
}
.flex.--align-center {
  align-items: center;
}
.flex.--align-bottom {
  align-items: flex-end;
}
.flex.--align-self-end {
  align-self: flex-end;
}
.flex.--wrap {
  flex-wrap: wrap;
}
.flex.--wrap-reverse {
  flex-wrap: wrap-reverse;
}
.flex.--no-wrap {
  flex-wrap: nowrap;
}
.flex.--row {
  flex-direction: row;
}
.flex.--column {
  flex-direction: column;
}

/*
 * Grid
 *
 * Simle 12 column grid. Nothing fancy.
 */
/*
 * Container
 */
.grid-container {
  position: relative;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 24px;
  -webkit-transition: padding 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  -moz-transition: padding 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  -o-transition: padding 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: padding 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.grid-container.--full {
  max-width: 100%;
}
@media screen and (min-width: 425px) {
  .grid-container {
    padding: 32px;
  }
}
@media screen and (min-width: 768px) {
  .grid-container {
    padding: 40px;
  }
}
@media screen and (min-width: 1024px) {
  .grid-container {
    padding: 56px;
  }
}
@media screen and (min-width: 1366px) {
  .grid-container {
    padding: 72px;
  }
}

/*
 * Grid
 */
.grid-row {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -12px;
}
.grid-row.--reverse {
  flex-wrap: wrap-reverse;
}
.grid-row.--row-reverse {
  flex-direction: row-reverse;
}
.grid-row.--justify-center {
  justify-content: center;
}
.grid-row.--justify-right {
  justify-content: right;
}
.grid-row.--justify-space {
  justify-content: space-between;
}
.grid-row.--align-center {
  align-items: center;
}
.grid-row.--align-bottom {
  align-items: flex-end;
}
.grid-row.--wrap-no-wrap {
  flex-wrap: nowrap;
}
.grid-row.--height-100 {
  height: 100%;
}
@media screen and (min-width: 768px) {
  .grid-row.--justify-center-md {
    justify-content: center;
  }
}

/*
 * columns
 */
[class*=col-] {
  position: relative;
  margin-bottom: 16px;
  padding: 0 12px;
}

/*
 * Margins
 */
.col-no-margin {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .col-no-margin-md {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1024px) {
  .col-no-margin-lg {
    margin-bottom: 0;
  }
}
/*
 * Sizes & Breackpoints
 *
 * Bases on mobile first practices.
 */
.col-xs-12 {
  width: 100%;
}

.col-xs-11 {
  width: 91.67%;
}

.col-xs-10 {
  width: 83.33%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-8 {
  width: 66.67%;
}

.col-xs-7 {
  width: 58.33%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-5 {
  width: 41.67%;
}

.col-xs-4 {
  width: 33.33%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-2 {
  width: 16.67%;
}

.col-xs-1 {
  width: 8.33%;
}

@media screen and (min-width: 425px) {
  .col-sm-12 {
    width: 100%;
  }

  .col-sm-11 {
    width: 91.67%;
  }

  .col-sm-10 {
    width: 83.33%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-8 {
    width: 66.67%;
  }

  .col-sm-7 {
    width: 58.33%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-5 {
    width: 41.67%;
  }

  .col-sm-4 {
    width: 33.33%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-2 {
    width: 16.67%;
  }

  .col-sm-1 {
    width: 8.33%;
  }
}
@media screen and (min-width: 768px) {
  .col-md-12 {
    width: 100%;
  }

  .col-md-11 {
    width: 91.67%;
  }

  .col-md-10 {
    width: 83.33%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-8 {
    width: 66.67%;
  }

  .col-md-7 {
    width: 58.33%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-5 {
    width: 41.67%;
  }

  .col-md-4 {
    width: 33.33%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-2 {
    width: 16.67%;
  }

  .col-md-1 {
    width: 8.33%;
  }
}
@media screen and (min-width: 1024px) {
  .col-lg-12 {
    width: 100%;
  }

  .col-lg-11 {
    width: 91.67%;
  }

  .col-lg-10 {
    width: 83.33%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-8 {
    width: 66.67%;
  }

  .col-lg-7 {
    width: 58.33%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-5 {
    width: 41.67%;
  }

  .col-lg-4 {
    width: 33.33%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-2 {
    width: 16.67%;
  }

  .col-lg-1 {
    width: 8.33%;
  }
}
.grid-full-page {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

/*
 * Typography
 *
 * Control site fonts.
 */
/*
 * Base
 */
html,
body,
:root {
  font-family: Whitney, helvetica, sans-serif;
  color: #000000;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: grayscale;
}

/*
 * Load Fonts
 */
/*
 * headlines, paragraphs, and lists
 */
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin: 0;
}
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child,
p:first-child,
ul:first-child,
ol:first-child {
  margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Whitney, helvetica, sans-serif;
}

h1 {
  font-size: 40px;
  font-weight: 900;
  line-height: 48px;
  margin-top: 16px;
}

h2 {
  font-size: 28px;
  line-height: 33px;
  margin-top: 12px;
}
@media screen and (min-width: 768px) {
  h2 {
    font-size: 40px;
    line-height: 48px;
    margin-top: 16px;
  }
}
@media screen and (min-width: 1024px) {
  h2 {
    font-size: 48px;
    line-height: 56px;
  }
}

h3 {
  font-size: 20px;
  line-height: 24px;
  margin-top: 12px;
}
@media screen and (min-width: 768px) {
  h3 {
    font-size: 32px;
    line-height: 40px;
    margin-top: 16px;
  }
}
@media screen and (min-width: 1024px) {
  h3 {
    font-size: 40px;
    line-height: 48px;
  }
}

h4 {
  font-size: 18px;
  line-height: 26px;
  margin-top: 12px;
}
@media screen and (min-width: 768px) {
  h4 {
    font-size: 24px;
    line-height: 32px;
    margin-top: 16px;
  }
}
@media screen and (min-width: 1024px) {
  h4 {
    font-size: 32px;
    line-height: 40px;
  }
}

h5 {
  font-size: 16px;
  line-height: 24px;
  margin-top: 12px;
}
@media screen and (min-width: 768px) {
  h5 {
    font-size: 18px;
    line-height: 26px;
    margin-top: 16px;
  }
}
@media screen and (min-width: 1024px) {
  h5 {
    font-size: 24px;
    line-height: 32px;
  }
}

h6 {
  font-size: 14px;
  line-height: 22px;
  margin-top: 12px;
}
@media screen and (min-width: 768px) {
  h6 {
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
  }
}

p,
ul,
ol {
  font-size: 14px;
  line-height: 20px;
  margin-top: 12px;
}
@media screen and (min-width: 768px) {
  p,
ul,
ol {
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
  }
}

p.lead {
  font-size: 18px;
  line-height: 24px;
}
@media screen and (min-width: 768px) {
  p.lead {
    font-size: 24px;
    line-height: 32px;
  }
}

/* Links
    ========================================================================== */
a {
  text-decoration: none;
  outline: none;
  border: 0;
  color: #000000;
  cursor: pointer;
}

a:focus {
  outline: none;
}

/* Selections
    ========================================================================== */
::selection {
  background: #000000;
  color: #FFFFFF;
  text-shadow: none;
}

::-webkit-selection {
  background: #000000;
  color: #FFFFFF;
  text-shadow: none;
}

::-moz-selection {
  background: #000000;
  color: #FFFFFF;
  text-shadow: none;
}

/*
 * Components
 */
/*
 * Sections
 *
 * All section styles.
 */
main {
  text-align: center;
}
main a {
  display: inline-block;
  margin: 32px 0 0 16px;
}
main a:first-child {
  margin-left: 0;
}

svg {
  width: 100%;
  max-width: 176px;
  height: auto;
}
svg.icon {
  max-width: 40px;
}
/*# sourceMappingURL=index.0403f351.css.map */
